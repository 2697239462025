export default function formatDatetoUTC8(uctDateSting){
    //2005-03-08T23:00:00.000Z
    if (!uctDateSting) return '';
    const utcDate = new Date(uctDateSting);
    if (isNaN(utcDate)) return 'Invalid Date';
    const taipeiDate = new Date(utcDate.toLocaleString('en-US', { timeZone: 'Asia/Taipei' }));
    const year = taipeiDate.getFullYear();
    const month = String(taipeiDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(taipeiDate.getDate()).padStart(2, '0');
    const hours = String(taipeiDate.getHours()).padStart(2, '0');
    const minutes = String(taipeiDate.getMinutes()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
};